<template>
  <section
    v-if="articles?.length"
    class="pinned-articles">
    <div>
      <h2
        v-if="!noHeading"
        class="pinned-articles__heading"
        :class="headingClass">
        {{ heading ? heading : "Popular articles" }}
      </h2>

      <article-card-grid
        :content="currentContent"
        :article-ids="articleIds"
        class="pinned-articles__list" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { ContentItem } from "@edco/apollo";

const props = withDefaults( defineProps<{
  heading?: string | null,
  headingClass?: string | null,
  articles?: ContentItem[] | null,
  currentContent: ContentItem,
  noHeading?: boolean
}>(), {
  heading: null,
  headingClass: null,
  articles: null,
  noHeading: false,
} );

const articleIds = computed( () => props.articles?.map( ( a ) => a.id ) ) ?? [];

</script>

<style lang="scss" scoped>
.pinned-articles {
  margin: 30px 0 60px;

  &__heading {
    margin-bottom: 30px;
  }
}

</style>
